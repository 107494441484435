import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IdTitleSelector } from '@core/models/ui.models';
import { MatSelectionList } from '@angular/material/list';

const LIMIT_FOR_VIRTUAL_SCROLL_TOGGLE = 10;

/**
 * @description меню с выпадающим списком элементов для встраивания в шапку таблицы
 * @param selected выбранный элемент списка
 * @param options список элементов для выбора
 * @param filterChange эмиттер изменения выбранного элемента
 */

@Component({
    selector: 'nekta-table-filter',
    templateUrl: './nekta-table-filter.component.html',
    styleUrls: ['./nekta-table-filter.component.less']
})
export class NektaTableFilterComponent<T = any, S = any> implements OnInit, OnChanges {
    @Input() selected: T | null = null;
    @Input() options: IdTitleSelector[] = [];
    @Input() devices: any[] = [];
    @Input() isCollectionTimeshiftComponent = false;

    @Output() filterChange: EventEmitter<T> = new EventEmitter<T>();

    filteredOptions: IdTitleSelector<T, S>[] = [];
    searchString: string | null = null;
    selectedOption: T | null = null;

    withVirtualScroll = false;

    @ViewChild('matSelectionList') matSelectionList: MatSelectionList;

    ngOnInit(): void {
        this.withVirtualScroll = this.options.length > LIMIT_FOR_VIRTUAL_SCROLL_TOGGLE;
        this.filteredOptions = this.options.slice(0);
        if (this.isCollectionTimeshiftComponent) {
            const modelIds = new Set(this.devices.map(device => device.model_id));
            this.filteredOptions = this.filteredOptions.filter(option => modelIds.has(option.id))
        } else {}

        if (this.selected !== null && this.selected !== undefined) {
            this.selectedOption = this.selected;
        } else {
            this.selectedOption = null;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.selected?.currentValue !== changes?.selected?.previousValue) {
            if (this.selected === undefined) {
                this.selectedOption = null;
                if (this.matSelectionList) {
                    this.matSelectionList.deselectAll();
                }
            }
        }
    }

    onFilterChange(v: T | null) {
        this.selectedOption = v;
        this.filterChange.emit(v);
    }

    onSearch(str: string | null) {
        this.searchString = str?.toLowerCase();
        if (!str) {
            this.filteredOptions = this.options.slice(0);
        } else {
            this.filteredOptions = this.options.filter((w) => w.title?.toLowerCase().includes(this.searchString));
        }
    }

    trackByFn(index: number, item: IdTitleSelector) {
        return item?.id;
    }
}
